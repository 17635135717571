

function initProductGallerySlider() {
	const galleryThumbs = new Swiper('.gallery-thumbs', {

		direction: 'horizontal',
		slidesPerView: 5,
		spaceBetween: 10,
		watchSlidesProgress: true,
		loop: true,
		breakpoints: {
			1439: {
				spaceBetween: 23,
				direction: 'vertical',
				loop: true,
			},
			991: {
				spaceBetween: 16,
				direction: 'vertical',
				loop: true,
			},
			767: {
				spaceBetween: 16,
				direction: 'vertical',
				loop: true,
			},
		},
	});

	const galleryTop = new Swiper('.gallery-top', {
		slidesPerView: 1,
		loop: true,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		thumbs: {
			swiper: galleryThumbs,
		},
	});

}
 function initFancybox(){
	 Fancybox.bind("[data-fancybox]", {
		 // Your custom options
	 });
 }

function toggleCollapse() {
	const toggleCollapseBtn = document.querySelectorAll(
		".js-collapse-btn"
	);

	toggleCollapseBtn?.forEach(function (btn) {
		btn.addEventListener("click", function () {
			const parentWrapper = btn.closest(".js-collapse");
			const content = parentWrapper.querySelector(
				".js-collapse-content"
			);

			if (parentWrapper.classList.contains("open")) {
				content.style.maxHeight = 0;
			} else {
				content.style.maxHeight = content.scrollHeight + "px";
			}

			parentWrapper.classList.toggle("open");
		});
	});
}

function initLanguageSwitcher(){
	let selectContainer = document.querySelector(".js-language-container");
	let select = document.querySelector(".js-language-select");
	let input = document.querySelector(".js-language-input");
	let options = document.querySelectorAll(".js-language-container .js-language-option");

	select.onclick = (event) => {
		selectContainer.classList.toggle("active");
		event.stopPropagation();
	};

	options.forEach((e) => {
		e.addEventListener("click", () => {
			input.value = e.innerText;
			selectContainer.classList.remove("active");
			options.forEach((e) => {
				e.classList.remove("selected");
			});
			e.classList.add("selected");
		});
	});
	document.addEventListener("click", (event) => {
		if (selectContainer.classList.contains("active") && !selectContainer.contains(event.target)) {
			selectContainer.classList.remove("active");
		}
	});
}

$( document ).ready(function() {
	initProductGallerySlider();
	initFancybox();
	toggleCollapse();
	initLanguageSwitcher()
});
